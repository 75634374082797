import * as React from 'react'
import Layout from '../../components/layout'
import FinialsHero from '../../components/hero/finials-hero'
import { Link } from 'gatsby'
import Lightbox from 'yet-another-react-lightbox'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import '@fontsource/montserrat'
import {
  buttonsContainer
} from'../../lib/buttons.module.css'
import {
  containerLightBox,
  galleryContainer,
  gallery
} from '../../lib/whatsnew.module.css'
import { StaticImage } from "gatsby-plugin-image"

const activeStyles = {
  color: 'brown'
}

const apuliaSlides = [
  { src: '/apulia/apiliacocoa.png', title: 'Apulia Cocoa' },
  { src: '/apulia/apuliacottageash.png', title: 'Apulia Cottage Ash' },
  { src: '/apulia/apuliaoatmealash.png', title: 'Apulia Oatmeal Ash' },
];

const austelSlides = [
  { src: '/austel/austelbaroquesilver.png', title: 'Austel Baroque Silver' },
  { src: '/austel/austeldarkpewter.png', title: 'Austel Dark Pewter' },
];

const ballSlides = [
  { src: '/ball/mlballdarkpewter.png', title: 'Modern Lined Ball Dark Pewter' },
  { src: '/ball/mlballgoldoverwhite.png', title: 'Modern Lined Ball Gold Over White' },
  { src: '/ball/mlbdistressedivory.png', title: 'Modern Lined Ball Distressed Ivory' },
  { src: '/ball/mlbgoldoverblack.png', title: 'Modern Lined Ball Gold Over Black' },
];

const mandarinSlides = [
  { src: '/mandarin/mandarinoatmelash.png', title: 'Mandarin Oatmeal Ash' },
];

const parhamSlides = [
  { src: '/parham/parhambaroquesilver.png', title: 'Parham Baroque Silver' },
  { src: '/parham/parhamdarkpewter.png', title: 'Parham Dark Pewter' },
  { src: '/parham/parhamdistressedivory.png', title: 'Parham Distressed Ivory' },
  { src: '/parham/parhamgoldoverblack.png', title: 'Parham Gold Over Black' },
  { src: '/parham/parhamgoldoverwhite.png', title: 'Parham Gold Over White' },
];

const tamburoSlides = [
  { src: '/tamburo/tamburocottageash.png', title: 'Tamburo Cottage Ash' },
  { src: '/tamburo/tamburowindsorash.png', title: 'Tamburo Windsor Ash' },
];

const vaseSlides = [
  { src: '/vase/vasebaroquesilver.png', title: 'EA Vase Baroque Silver' },
  { src: '/vase/vasecocoa.png', title: 'EA Vase Cocoa' },
  { src: '/vase/vasecottageash.png', title: 'EA Vase Cottage Ash' },
  { src: '/vase/vasedarkpewter.png', title: 'EA Vase Dark Pewter' },
  { src: '/vase/vasedistressedivory.png', title: 'EA Vase Distressed Ivory' },
  { src: '/vase/vasegoldoverblack.png', title: 'EA Vase Gold Over Black' },
  { src: '/vase/vasegoldoverwhite.png', title: 'EA Vase Gold Over White' },
  { src: '/vase/vaseoatmelash.png', title: 'EA Vase Oatmeal Ash' },
  { src: '/vase/vasewindsorash.png', title: 'EA Vase Windsor Ash' },
];

const viscountSlides = [
  { src: '/viscount/viscountbaroquesilver.png', title: 'Viscount Baroque Silver' },
  { src: '/viscount/viscountdarkpewter.png', title: 'Viscount Dark Pewter' },
  { src: '/viscount/viscountdistressedivory.png', title: 'Viscount Distressed Ivory' },
  { src: '/viscount/viscountgoldoverblack.png', title: 'Viscount Gold Over Black' },
  { src: '/viscount/viscountgoldoverwhite.png', title: 'Viscount Gold Over White' },
];

const Finials = () => {  
  const [openApulia, setOpenApulia] = React.useState(false);
  const [openAustel, setOpenAustel] = React.useState(false);
  const [openBall, setOpenBall] = React.useState(false);
  const [openMandarin, setOpenMandarin] = React.useState(false);
  const [openParham, setOpenParham] = React.useState(false);
  const [openTamburo, setOpenTamburo] = React.useState(false);
  const [openVase, setOpenVase] = React.useState(false);
  const [openViscount, setOpenViscount] = React.useState(false);
  return (
    <Layout pageTitle="Finials">
      <FinialsHero />
      <div className={buttonsContainer} style={{width:"800px", margin:"auto"}}>
        <div><Link to="/whatsnew/finials" activeStyle={activeStyles} partiallyActive={true}>Finials</Link></div>
        <div><Link to="/whatsnew/newbespoke" activeStyle={activeStyles} partiallyActive={true}>New Bespoke</Link></div>
      </div>

    <div className={galleryContainer}>
    <div className={gallery}>
      <div>
        <button type="button" onClick={() => setOpenApulia(true)}>
          <StaticImage
            layout="CONSTRAINED"
            width= "640"
            alt="Finial"
            src={"../../images/whatsnew/finialsthumbs/apuliaoatmealash.png"}
            formats={["webp", "avif"]}
            placeholder="tracedSVG"
            quality="100"
          />
        </button>
        <h3>Apulia</h3>
        <Lightbox
          className={containerLightBox}
          plugins={[Thumbnails, Captions]}
          slides={apuliaSlides}
          open={openApulia}
          close={() => setOpenApulia(false)}
        />
      </div>
      <div>
        <button type="button" onClick={() => setOpenAustel(true)}>
          <StaticImage
            layout="CONSTRAINED"
            width= "640"
            alt="Finial"
            src={"../../images/whatsnew/finialsthumbs/austeldarkpewter.png"}
            formats={["webp", "avif"]}
            placeholder="tracedSVG"
            quality="100"
          />
        </button>
        <h3>Austel</h3>
        <Lightbox
          className={containerLightBox}
          plugins={[Thumbnails, Captions]}
          slides={austelSlides}
          open={openAustel}
          close={() => setOpenAustel(false)}
        />
      </div>
      <div>
        <button type="button" onClick={() => setOpenBall(true)}>
          <StaticImage
            layout="CONSTRAINED"
            width= "640"
            alt="Finial"
            src={"../../images/whatsnew/finialsthumbs/mlbgoldoverblack.png"}
            formats={["webp", "avif"]}
            placeholder="tracedSVG"
            quality="100"
          />
        </button>
        <h3>Modern Lined Ball</h3>
        <Lightbox
          className={containerLightBox}
          plugins={[Thumbnails, Captions]}
          slides={ballSlides}
          open={openBall}
          close={() => setOpenBall(false)}
        />
      </div>
      <div>
        <button type="button" onClick={() => setOpenMandarin(true)}>
          <StaticImage
            layout="CONSTRAINED"
            alt="Finial"
            width= "640"
            src={"../../images/whatsnew/finialsthumbs/mandarinoatmelash.png"}
            formats={["webp", "avif"]}
            placeholder="tracedSVG"
            quality="100"
          />
        </button>
        <h3>Mandarin</h3>
        <Lightbox
          className={containerLightBox}
          plugins={[Thumbnails, Captions]}
          slides={mandarinSlides}
          open={openMandarin}
          close={() => setOpenMandarin(false)}
        />
      </div>
      <div>
        <button type="button" onClick={() => setOpenParham(true)}>
          <StaticImage
            layout="CONSTRAINED"
            alt="Finial"
            width= "640"
            src={"../../images/whatsnew/finialsthumbs/parhamgoldoverwhite.png"}
            formats={["webp", "avif"]}
            placeholder="tracedSVG"
            quality="100"
          />
        </button>
        <h3>Parham</h3>
        <Lightbox
          className={containerLightBox}
          plugins={[Thumbnails, Captions]}
          slides={parhamSlides}
          open={openParham}
          close={() => setOpenParham(false)}
        />
      </div>
      <div>
        <button type="button" onClick={() => setOpenTamburo(true)}>
          <StaticImage
            layout="CONSTRAINED"
            alt="Finial"
            width= "640"
            src={"../../images/whatsnew/finialsthumbs/tamburocottageash.png"}
            formats={["webp", "avif"]}
            placeholder="tracedSVG"
            quality="100"
          />
        </button>
        <h3>Tamburo with Rope</h3>
        <Lightbox
          className={containerLightBox}
          plugins={[Thumbnails, Captions]}
          slides={tamburoSlides}
          open={openTamburo}
          close={() => setOpenTamburo(false)}
        />
      </div>
      <div>
        <button type="button" onClick={() => setOpenVase(true)}>
          <StaticImage
            layout="CONSTRAINED"
            alt="Finial"
            width= "640"
            src={"../../images/whatsnew/finialsthumbs/vasedarkpewter.png"}
            formats={["webp", "avif"]}
            placeholder="tracedSVG"
            quality="100"
          />
        </button>
        <h3>EA Vase</h3>
        <Lightbox
          className={containerLightBox}
          plugins={[Thumbnails, Captions]}
          slides={vaseSlides}
          open={openVase}
          close={() => setOpenVase(false)}
        />
      </div>
      <div>
        <button type="button" onClick={() => setOpenViscount(true)}>
          <StaticImage
            layout="CONSTRAINED"
            alt="Finial"
            width= "640"
            src={"../../images/whatsnew/finialsthumbs/viscountgoldoverblack.png"}
            formats={["webp", "avif"]}
            placeholder="tracedSVG"
            quality="100"
          />
        </button>
        <h3>Viscount</h3>
        <Lightbox
          className={containerLightBox}
          plugins={[Thumbnails, Captions]}
          slides={viscountSlides}
          open={openViscount}
          close={() => setOpenViscount(false)}
        />
      </div>
    </div>
    </div>
    </Layout>
  )
}

export default Finials